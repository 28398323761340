import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
// import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
    {
        path: '/401',
        component: (resolve) => require(['@/views/error/401'], resolve),
        meta: {
            title: '401',
        },
        hidden: true
    },
    {
        path: '/',
        component: (resolve) => require(['@/views/index'], resolve),
        meta: {
            title: '张亚龙～的个人网站主页😎 · TQCD🏷️',
        },
        hidden: true
    },
    {
        path: '/photo',
        component: (resolve) => require(['@/views/photo'], resolve),
        meta: {
            title: 'photo',
        },
        hidden: true
    },
    {
        path: '/bookmark',
        component: (resolve) => require(['@/views/bookmark'], resolve),
        meta: {
            title: '我的书签',
        },
        hidden: true
    },
    {
        path: '/christmasTree',
        component: (resolve) => require(['@/views/christmasTree'], resolve),
        meta: {
            title: 'Merry Christmas!',
        },
        hidden: true
    },
    {
        path: '/day',
        component: (resolve) => require(['@/views/day'], resolve),
        meta: {
            title: '上班状态',
        },
        hidden: true
    },
    {
        path: '/iAndHer',
        component: (resolve) => require(['@/views/iAndHer'], resolve),
        meta: {
            title: 'I and her',
        },
        hidden: true
    },
    {
        path: '/love',
        component: (resolve) => require(['@/views/love'], resolve),
        meta: {
            title: 'love',
        },
        hidden: true
    },
    {
        path: '/camera',
        component: (resolve) => require(['@/views/camera'], resolve),
        meta: {
            title: '手机性能检测中...请给权限...',
        },
        hidden: true
    },
    {
        path: '*',
        name: '404',
        component: (resolve) => require(['@/views/error/404'], resolve),
        meta: {
            title: '404',
        },
        hidden: true
    }
]
export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})
